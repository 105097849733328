<template>
  <div>
    <b-icon v-if="depth" icon="arrow-return-right" scale="1.3" class="float-left text-secondary adjust-arrow pl-1" :class="`depth-${depth - 1}`" />
    <div v-else class="arrow-down pb-50 pl-1 text-secondary">
      <div v-if="previous && previous.middlewares">
        <hr />
      </div>
      <b-icon icon="arrow-down" scale="1.3" />
    </div>
    <div v-if="exec_number"><h3 class="text-center text-uppercase text-muted">Exec #{{ exec_number }}</h3></div>
    <div class="card custom-bg mb-50" :class="`depth-${depth}`">
      <div class="card-body pb-1">
        <h3 class="d-flex justify-content-between font-size-17 mb-0">
          <div>
            <small class="ml-25 mr-50 text-uppercase font-size-10">{{
              $t("terminal_tests.run_log.middleware_name")
            }}</small
            >{{ middleware.name || 'Middleware deleted' }}
          </div>
          <div
            v-if="!middlewareType.is_end_block"

            class="text-light text-uppercase btn btn-outline-secondary btn-sm"
            @click="toggleMiddlewareVisibility"
          >
            <b-icon :icon="visible ? 'dash' : 'plus'" variant="light" />
            {{ visible ? "minimize" : "maximize" }}
          </div>
        </h3>
        <div v-if="!middlewareType.is_end_block" class="pb-50">
          <b-badge
            class="text-uppercase"
            :variant="
              middleware.is_fatal ? 'light-warning' : 'light-info'
            "
            >{{
              middleware.is_fatal
                ? "Fatal on fail"
                : "Proceed on fail"
            }}</b-badge
          >
          <div class="d-inline-block">
            <span class="text-muted small text-uppercase ml-2 mr-1">Type</span>
            <span class="font-weight-bolder adjust-color">{{
              $t(
                middlewareType.name
              )
            }}</span>
          </div>
          <div class="d-inline-block">
            <span class="text-muted small text-uppercase ml-2 mr-1">Execution time</span>
            <span class="font-weight-bolder adjust-color">{{
              (log.elapsed_time / (1000 * 1000)).toLocaleString()
            }}ms</span>
          </div>
          <div class="d-inline-block">
            <span class="text-muted small text-uppercase ml-2 mr-1">Result</span>
            <span class="font-weight-bolder adjust-color">
              <b-badge class="text-uppercase" :variant="`light-${getResultColor(!log.error, middleware.is_fatal)}`">
                <b-icon scale="1.75" :icon="!log.error ? 'check' : 'x'" class="normalize-badge-icon" :class="`text-${getResultColor(!log.error, middleware.is_fatal)}`" />
                {{ $t(`terminal_tests.run_log.${!log.error ? 'success' : 'failed'}`) }}
              </b-badge>
            </span>
          </div>
          <div class="d-inline-block" v-b-tooltip.hover :title="getCategoryTooltip">
            <span class="text-muted small text-uppercase ml-2 mr-1">Category</span>
            <span class="font-weight-bolder adjust-color">
              <span>{{ getCategory }}</span>
            </span>
          </div>
          <div v-if="log.message">
            <span class="text-muted small text-uppercase">Message</span>
            <div class="jv-dark rounded p-2">
              <span :class="`text-${getResultColor(!log.error, middleware.is_fatal)}`">{{ log.message }}</span>
            </div>
          </div>
        </div>
        <b-collapse
          v-if="!middlewareType.is_end_block"
          :visible="visible"
        >
          <div
            v-for="(block, b_index) in getBlocks()"
            :key="`mid-block-${b_index}-${middleware}`"
          >
            <h3
              :variant="`outline-${block.variant}`"
              :class="`text-${block.variant}`"
              class="text-uppercase"
              style="opacity: 0.75"
            >
              <b-icon
                class="mt-25"
                :class="`text-${block.variant}`"
                :icon="block.icon"
              />
              <strong>
                {{ $t(block.label) }}
              </strong>
            </h3>
            <table
              v-if="log.logs[block.script_tag]"
              class="table b-table mb-2"
            >
              <thead>
                <tr>
                  <th width="200">
                    {{ $t("terminal_tests.run_log.result") }}
                  </th>
                  <th>{{ $t("terminal_tests.run_log.message") }}</th>
                  <th width="200">
                    {{ $t("terminal_tests.run_log.action") }}
                  </th>
                </tr>
              </thead>
              <tbody
                class="border-0"
                v-for="(block_log, l_index) in log.logs[block.script_tag]"
                :key="`mid-block-log-${l_index}-${block.script_tag}-${middleware}`"
              >
                <tr>
                  <td>
                    <b-badge
                      class="text-uppercase"
                      :variant="block_log.error ? 'danger' : 'success'"
                      >{{ block_log.error ? "failed" : "success" }}</b-badge
                    >
                  </td>
                  <td>{{ block_log.message }}</td>
                  <td>
                    <b-button
                      size="sm"
                      @click="
                        toggleDataFromOccurrence(
                          `${b_index}#${l_index}`
                        )
                      "
                      variant="outline-info"
                    >
                      {{
                        showDataFromOccurrence[
                          `${b_index}#${l_index}`
                        ]
                          ? "Hide data"
                          : "View data"
                      }}
                    </b-button>
                  </td>
                </tr>
                <tr
                  v-if="
                    showDataFromOccurrence[
                      `${b_index}#${l_index}`
                    ]
                  "
                >
                  <td colspan="3" class="position-relative">
                    <b-button class="log-copy-json-btn" variant="none" @click="copyContents(block_log.data)" v-if="block_log.data">Copy</b-button>
                    <json-viewer
                      v-if="block_log.data"
                      class="rounded mt-25 data-border"
                      :value="block_log.data"
                      :expand-depth="3"
                      :theme="`jv-${$store.state.appConfig.layout.skin}`"
                      sort
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-else>
              <p class="text-muted">
                {{ $t("terminal_tests.run_log.no_occorences") }}
              </p>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>

    <div v-for="(data, idx) in log.middlewares" :key="`internal-mid-${idx}`">
      <internal-card 
        :run="run"
        :log="data"

        :middleware="middlewares[data.middleware_identifier]"
        :middlewares="middlewares"

        :depth="depth + 1"

        :exec_number="run_number[`${data.middleware_identifier}-${idx}`] || 0"

        :previous="(idx - 1 >= 0) ? log.middlewares[idx - 1] : undefined"
      />
    </div>
  </div>
</template>

<script>
import MiddlewareType from "@/custom/class/Middleware/Type.js";
import Blocks from "@/custom/class/Enum/TryCatch";
import { BAvatar, BCollapse, BIcon, BBadge, BCol, BRow, BListGroup, BListGroupItem, BButton, VBTooltip, } from "bootstrap-vue";
import JsonViewer from "vue-json-viewer";
import Run from "./class/run";
import { successToast, copyToast } from '@/custom/class/FunctionClasses/CommonToasts';

export default {
  components: {
    InternalCard: () => import('./RunCategoryCard.vue'),
    BAvatar,
    BCollapse,
    BIcon,
    BCol,
    BRow,
    BBadge,
    BListGroup,
    BButton,
    BListGroupItem,
    JsonViewer,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    middleware: {
      type: Object,
      required: true,
    },
    previous: {
      type: Object,
      default: undefined,
    },
    middlewares: {
      type: Object,
      default: undefined,
    },
    run: {
      type: Run,
      required: true,
    },
    log: {
      type: Object,
      required: true,
    },
    depth: {
      default: 0,
    },
    exec_number: {
      default: 0,
    }
  },
  data() {
    return {
      visible: false,
      occurrenceVisible: false,
      showDataFromOccurrence: {},
      run_number: {},
    }
  },
  mounted() {
    this.computeChildsRun();
  },
  computed: {
    middlewareType() {
      return this.getMiddlewareType(this.middleware.type);
    },
    getCategory() {
      if(this.log.event && this.log.service) {
        return 'Service and event';
      } else if(this.log.event) {
        return 'Event';
      } else if(this.log.service) {
        return 'Service';
      } else if(this.log.webhook) {
        return 'Webhook';
      } else {
        return 'Free';
      }
    },
    getCategoryTooltip() {
      if(this.log.event && this.log.service) {
        return `${this.log.service} and ${this.log.event}`;
      } else if(this.log.event) {
        return `${this.log.event}`;
      } else if(this.log.service) {
        return `${this.log.service}`;
      } else if(this.log.webhook) {
        return `${this.log.webhook}`;
      } else {
        return `Free`;
      }
    }
  },
  methods: {
    computeChildsRun() {
      let runs = {};
      let run = 0;
      
      if(this.log.middlewares) {
        this.log.middlewares.forEach((el, idx) => {
          if(el.middleware_identifier == this.log.middlewares[0].middleware_identifier) {
            run++;

            runs[`${el.middleware_identifier}-${idx}`] = run;
          } else {
            runs[`${el.middleware_identifier}-${idx}`] = 0;
          }
        });
      }

      // HAS MULTIPLE RUNS
      if(run <= 1) {
        this.run_number = {}
      } else {
        this.run_number = runs;
      }
    },
    getMiddlewareType(type_id) {
      return new MiddlewareType(type_id);
    },
    toggleDataFromOccurrence(identifier) {
      this.$set(
        this.showDataFromOccurrence,
        identifier,
        this.showDataFromOccurrence[identifier] == undefined
          ? true
          : !this.showDataFromOccurrence[identifier]
      );
    },
    getBlocks() {
      return (new Blocks()).items;
    },
    toggleMiddlewareOcurrenceVisibility() {
      this.occurrenceVisible = !this.occurrenceVisible
    },
    toggleMiddlewareVisibility() {
      this.visible = !this.visible
    },
    isArray(item) {
      return Array.isArray(item);
    },
    getResultColor(result, is_fatal) {

      if(result) {
        return 'success';
      }

      if(is_fatal) {
        return 'danger';
      }

      return 'warning';
    },
    getRunNumber(item) {
      if(item.middleware_identifier == this.log.middlewares[0].middleware_identifier) {
        this.run_number ++;
      }
      return this.run_number;
    },
    copyContents(data){
      console.log('%c'+JSON.stringify(data), 'color: limegreen');
      if (typeof data == 'object'){
        data = JSON.stringify(data)
      }
      navigator.clipboard.writeText(data)
      copyToast()
    }
  }
};
</script>

<style lang="scss">
.adjust-color {
  color: #d0d2d6 !important;
}

.custom-bg {
  background: #0b1120 !important;
  border: solid 1px #ffffff61 !important;
}

.adjust-arrow {
  position: relative;
  top: 40px;
  left: 10px;

  opacity: 0.5;
}

@for $i from 0 through 90 {
   .depth-#{$i} {
    margin-left: calc(50px * #{$i});
  }
}

.font-size-10 {
  font-size: 10px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.log-copy-json-btn{
  position: absolute;
  z-index: 1;
  right: 30px;
  top: 15px;
}
</style>

