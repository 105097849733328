export default class TryCatch {
  constructor() {
      this.items = [
          {
              id: 1,
              label: "middleware.structure.try.title",
              variant: "info",
              script_tag: "try",
              api_tag: "try",
              icon: "arrow-return-right",
          },
          {
              id: 2,
              label: "middleware.structure.then.title",
              variant: "success",
              script_tag: "then",
              api_tag: "then",
              icon: "check2-all",
          },
          {
              id: 3,
              label: "middleware.structure.catch.title",
              variant: "danger",
              script_tag: "catch",
              api_tag: "catch",
              icon: "exclamation-triangle",
          },
      ];
  }
}