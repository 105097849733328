<template>
  <b-nav>
    <b-nav-item-dropdown
      id="dropdown-grouped"
      variant="link"
      right
      class="dropdown-language mr-1 ml-1"
    >
      <template #button-content>
        <b-img
          :src="currentLocale.img"
          height="14px"
          width="22px"
          :alt="currentLocale.locale"
        />
        <span class="ml-50 text-body">{{ currentLocale.name }}</span>
        <!-- <feather-icon class="mr-1 text-primary" :icon="(!dropdownActive ? 'ChevronDownIcon' : 'ChevronUpIcon')" size="14" /> -->
      </template>
      <b-dropdown-item
        v-for="localeObj in locales"
        :key="localeObj.locale"
        @click="$i18n.locale = localeObj.locale"
      >
        <b-img
          :src="localeObj.img"
          height="14px"
          width="22px"
          :alt="localeObj.locale"
        />
        <span class="ml-50">{{ localeObj.name }}</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </b-nav>
</template>

<script>
import { BNav, BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
    BNav,
  },
  data(){
    return{
      dropdownActive: false,
    }
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: 'en',
        img: require('@/assets/images/flags/en.png'),
        name: 'English',
      },
      // {
      //   locale: 'fr',
      //   img: require('@/assets/images/flags/fr.png'),
      //   name: 'François',
      // },
      // {
      //   locale: 'de',
      //   img: require('@/assets/images/flags/de.png'),
      //   name: 'Deutsch',
      // },
      {
        locale: 'pt',
        img: require('@/assets/images/flags/pt.png'),
        name: 'Português',
      },
    ]
    /* eslint-disable global-require */

    return {
      locales,
    }
  },
}
</script>

<style lang="scss">

</style>
