<template>
  <svg  viewBox="0, 0, 400,526.15894039735105" :style="`height: ${size}px; width:${size}px`" >
    <g>
      <path d="M145.471 6.225 C 145.943 14.965,150.856 123.162,150.786 123.253 C 150.754 123.294,124.412 113.571,92.248 101.645 C 60.084 89.720,33.703 80.031,33.624 80.114 C 33.364 80.386,-0.000 182.053,0.005 182.556 C 0.008 182.826,27.060 190.413,60.121 199.416 C 93.181 208.419,120.141 215.929,120.031 216.105 C 119.921 216.280,102.410 237.997,81.119 264.363 C 50.701 302.032,42.547 312.440,43.058 312.941 C 43.416 313.292,63.349 327.658,87.354 344.865 L 130.999 376.152 165.115 325.176 C 183.878 297.139,199.440 273.991,199.696 273.735 C 199.963 273.468,214.762 295.156,234.467 324.695 C 255.352 356.001,269.000 375.983,269.354 375.768 C 269.673 375.575,289.661 361.264,313.772 343.967 L 357.611 312.517 344.719 296.523 C 337.628 287.727,320.107 266.006,305.783 248.254 C 291.459 230.502,279.798 215.920,279.870 215.848 C 279.942 215.776,306.822 208.398,339.603 199.453 C 372.384 190.508,399.391 183.020,399.617 182.812 C 399.930 182.526,367.003 80.887,366.342 80.100 C 366.266 80.009,339.894 89.694,307.738 101.624 C 275.581 113.553,249.233 123.283,249.187 123.246 C 249.086 123.166,254.090 11.986,254.513 4.901 L 254.806 0.000 199.971 0.000 L 145.136 0.000 145.471 6.225 " 
        stroke="none" :fill="color" fill-rule="evenodd"
      >
      </path>
    </g>
  </svg>
</template>

<script>
  export default {
    props: {
      color: {
        type: String,
        default: 'var(--success)'
      },
      size:{
        type: Number,
        default: 20
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>