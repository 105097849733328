<template>
  <div class="d-inline-block mr-1 helper-container">
    <b-button 
      type="button" 
      pill
      size="sm"
      class="px-0 py-0 circle"
      variant="flat-info"
      @click="showModal"
    >
      <!-- variant="outline-info" -->
      <feather-icon
        :size="iconSize"
        icon="HelpCircleIcon"
      />
    </b-button>

    <b-modal
      :id="uuid"
      :size="mdSize"
      ok-only
      :title="title"
      :ok-title="$t('common.terms.ok')"
    >
      <span v-html="innerHTML"></span>
    </b-modal>

  </div>
</template>

<script>
import { BButton, BModal, VBModal } from 'bootstrap-vue'
import { v4 as uuidv4 } from "uuid";

  export default {
    props: {
      innerHTML: {
        type: String,
        required: true
      },
      title: {
        type: String,
        default: 'Helper'
      },
      mdSize: {
        type: String,
        default: 'md'
      },
      iconSize: {
        type: String,
        default: '20'
      },
      variant: {
        type: String,
        default: 'info'
      }
    },
    components: {
      BButton,
      BModal
    },
    directives: {
      'b-modal': VBModal,
    },
    data() {
      return { 
        uuid: uuidv4(),
      }
    },
    methods: {
      showModal() {
        this.$bvModal.show(this.uuid)
      }
    }
   
  }
</script>

<style lang="scss" scoped>

// @import "@/assets/scss/variables/_variables.scss";

.helper-container {
  position: relative;
  top: -3px;
}

</style>