<template>
  <div v-if="finishedLoading">
    <b-container fluid>
      <b-row>
        <b-col cols="12" class="text-center mb-2">
          <div class="d-inline-block">
            <div class="tfo-square-container">
              <div
                v-for="idx in totalSquares"
                :key="idx"
                class="tof-timeline-square"
                :id="`square-item-${idx}`"
                @click="
                  goToLogItem(`#log-item-${itensPerSquare * (idx - 1)}`)
                "
                :class="getItemLogVariant(squareStats[idx - 1])"
              >
                <b-tooltip
                  :target="`square-item-${idx}`"
                  triggers="hover"
                  no-fade
                  custom-class="adjust-z-index"
                >
                  {{
                    `${
                      squareStats[idx - 1] ? squareStats[idx - 1].success : 0
                    }/${
                      squareStats[idx - 1]
                        ? squareStats[idx - 1].success +
                          squareStats[idx - 1].error
                        : 0
                    }`
                  }}
                </b-tooltip>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          v-for="(item, idx) in build()"
          :key="idx"
          :id="`log-item-${idx}`"
        >
          <app-timeline-item
            class="list-unstyled pb-3"
            :variant="item.error ? 'danger' : 'success'"
          >
            <div
              class="
                d-flex
                flex-sm-row flex-column flex-wrap
                justify-content-between
                mb-1 mb-sm-1
                tof-timeline-header
              "
            >
              <h6>
                <span class="text-mutted small"
                  >{{
                    item.category == 1 ? $t("terminal_tests.terms.finished_middleware") : $t("terminal_tests.terms.occurrence")
                  }}:</span
                >
                <span class="font-weight-bolder">
                  {{ getMiddleware(item.identifier).name }}
                </span>
              </h6>
              <small
                class="font-weight-bolder"
                :class="idx > 0 ? 'text-muted' : 'text-muted'"
              >
                <b-badge
                  v-if="item.block"
                  :variant="getBlockColor(item.block)"
                  class="mr-2"
                >
                  {{
                    item.block
                  }}
                </b-badge>
                <b-badge
                  :variant="item.error ? 'danger' : 'success'"
                  class="mr-2"
                >
                  {{
                    $t(
                      item.error
                        ? "common.terms.error"
                        : "common.terms.success"
                    )
                  }}
                </b-badge>
                {{
                  `${idx > 0 ? "+" : ""} ${(
                    (item.created_at - ordered[0].created_at) /
                    1e6
                  ).toFixed(2)} `
                }}ms</small
              >
            </div>
            <b-row
              v-if="item.category == 1 && getMiddleware(item.identifier)"
              :class="item.error ? 'text-danger' : 'text-primary'"
            >
              <b-col cols="12">
                <p class="text-muted small m-0">{{ $t("terminal_tests.terms.message") }}</p>
                <p class="font-weight-bolder">{{ item.message }}</p>
              </b-col>
              <b-col cols="3" col-md="3">
                <p class="text-muted small m-0">{{ $t("terminal_tests.terms.execution_n") }}</p>
                <p class="font-weight-bolder">{{ item.exec_counter + 1 }}</p>
              </b-col>
              <b-col cols="6">
                <p class="text-muted small m-0">{{ $t("terminal_tests.terms.type") }}</p>
                <p class="font-weight-bolder">
                  {{
                    $t(
                      getMiddlewareType(
                        getMiddleware(item.identifier).enum_middleware_type_id
                      ).name
                    )
                  }}
                </p>
              </b-col>
              <b-col cols="3" col-md="3" v-if="getDirectionPart(item.direction, 2)">
                <p class="text-muted small m-0">{{ $t("terminal_tests.terms.direction") }}</p>
                <p class="font-weight-bolder">
                  {{ getDirectionPart(item.direction, 2) }}
                </p>
              </b-col>
              <b-col cols="3" col-md="3" v-if="getDirectionPart(item.direction, 0)">
                <p class="text-muted small m-0">{{ $t("terminal_tests.terms.event") }}</p>
                <p class="font-weight-bolder">
                  {{ getDirectionPart(item.direction, 0) }}
                </p>
              </b-col>
              <b-col cols="6" col-md="6" v-if="getDirectionPart(item.direction, 1)">
                <p class="text-muted small m-0">{{ $t("terminal_tests.terms.service") }}</p>
                <p class="font-weight-bolder">
                  {{ getDirectionPart(item.direction, 1) }}
                </p>
              </b-col>
              <b-col cols="3" col-md="3">
                <p class="text-muted small m-0">{{ $t("terminal_tests.terms.log_n") }}</p>
                <p class="font-weight-bolder">
                  {{ idx + 1 }}
                </p>
              </b-col>
            </b-row>
            <vue-perfect-scrollbar style="width: 90%">
              <div style="max-height: 150px; border-radius: 5px">
                <json-viewer
                  v-if="item.data"
                  class="rounded"
                  :value="item.data"
                  :expand-depth="3"
                  :theme="`jv-${$store.state.appConfig.layout.skin}`"
                  copyable
                  sort
                />
              </div>
            </vue-perfect-scrollbar>
          </app-timeline-item>
        </b-col>
      </b-row>
    </b-container>
  </div>
  <div v-else>
    <p class="text-center mt-3">
      <span class="ml-1">{{
        currentLoadingStepMessage[currentLoadingStep]
      }}</span>
    </p>
    <p class="text-center">
      <b-spinner type="grow" />
    </p>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BContainer,
  BBadge,
  BSpinner,
  BTooltip,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import JsonViewer from "vue-json-viewer";
import MiddlewareType from "@/custom/class/Middleware/Type.js";
import Run from "./Run/class/run";

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BBadge,
    AppTimeline,
    AppTimelineItem,
    VuePerfectScrollbar,
    JsonViewer,
    BSpinner,
    BTooltip,
  },
  props: {
    run: {
      type: Run,
      required: true,
    },
  },
  data() {
    return {
      middlewares: undefined,
      currentLoadingStep: 0,
      maxSteps: 2,
      currentLoadingStepMessage: {
        0: "Loading middlewares",
        1: "Preparing the timeline",
      },
      maxSquares: 56,
      squareStats: {},
      itensPerSquare: 0,
      ordered: null,
    };
  },
  computed: {
    finishedLoading() {
      return this.currentLoadingStep >= this.maxSteps;
    },
    totalSquares() {
      return Math.min(this.maxSquares, Object.keys(this.squareStats).length);
    }
  },
  mounted() {
    this.getMiddlewares();
  },
  methods: {
    build() {
      if (this.ordered) {
        return this.ordered;
      }

      const json = this.getJSON();
      const keys = Object.keys(json.log);

      let timeline = [];

      keys.forEach((direction_identifier) => {
        const direction = json.log[direction_identifier];
        const mid_keys = Object.keys(direction);

        mid_keys.forEach((mid_key, index) => {
          const arr = json.log[direction_identifier][mid_key];

          arr.forEach((log_el, exec_counter) => {
            timeline.push({
              created_at: log_el.created_at,
              error: log_el.error,
              message: log_el.message,
              category: 1,
              direction: direction_identifier,
              identifier: mid_key,
              data: null,
              exec_counter: exec_counter,
            });

            [
              "try",
              "then",
              "catch",
            ].forEach(block => {
              if (log_el.logs[block]) {
                log_el.logs[block].forEach((log_element) => {
                  timeline.push({
                    created_at: log_element.created_at,
                    error: log_element.error,
                    message: log_element.message,
                    category: 2,
                    direction: direction_identifier,
                    data: log_element.data,
                    identifier: mid_key,
                    exec_counter: exec_counter,
                    block: block,
                  });
                });
              }
            });
          });
        });
      });

      this.itensPerSquare = Math.ceil(timeline.length / this.maxSquares);

      this.ordered = timeline.sort(this.compare);

      timeline.forEach((element, index) => {
        const idx = Math.floor(index / this.itensPerSquare);
        if (this.squareStats[idx] == undefined) {
          this.squareStats[idx] = {
            error: 0,
            success: 0,
          };
        }
        this.squareStats[idx][element.error ? "error" : "success"]++;
      });

      this.currentLoadingStep = 2;

      return this.ordered;
    },
    getMiddlewares() {
      this.$store
        .dispatch("getAllMiddlewares", {
          transmissionID: this.$route.params.transmissionID,
        })
        .then((resp) => {
          const from_identifier = resp.reduce((previous, current) => {
            previous[current.identifier] = current;
            return previous;
          }, {});

          this.middlewares = from_identifier;

          this.currentLoadingStep = 1;

          this.build();
        })
        .catch((error) => {
          this.currentLoadingStep = 999;
          console.error(error);
        });
    },
    compare(a, b) {
      return a.created_at > b.created_at ? 1 : -1;
    },
    getJSON() {
      return this.run.json_return;
    },
    getMiddleware(identifier) {
      return this.middlewares[identifier];
    },
    getDirectionPart(direction, part_idx) {
      const parts = direction.split(".");
      if(parts[part_idx].length == 0) {
        return undefined;
      }
      return parts[part_idx];
    },
    getMiddlewareType(type_id) {
      return new MiddlewareType(type_id);
    },
    goToLogItem(logItem) {
      location.href = logItem;
    },
    getItemLogVariant(item) {
      if (!item || item.error + item.success == 0) {
        return "bg-secondary";
      }

      const pct = (item.error / (item.error + item.success)) * 100;
      if (pct == 0) {
        return "bg-success";
      }

      if (pct >= 0 && pct <= 10) {
        return "bg-yellow";
      }

      if (pct > 10 && pct <= 30) {
        return "bg-warning";
      }

      return "bg-danger";
    },
    getBlockColor(block) {
      return {
        try: "info",
        then: "success",
        catch: "danger",
      }[block];
    }
  },
};
</script>

<style lang="scss" scoped>
.tof-timeline-header {
  background: #2c2c2c;
  padding: 5px;
  padding-top: 7px;
  padding-bottom: 3px;
  border-radius: 5px;
  padding-right: 10px;
}

.tof-timeline-square {
  display: inline-block;
  margin: 2px;
  margin-top: 0px;
  margin-bottom: 0px;
  border-radius: 3px;
  width: 15px;
  height: 15px;
  background: #000;
  opacity: 0.65;

  &:hover {
    opacity: 1;
  }
}

.bg-yellow {
  background: #ffc107 !important;
}

.tfo-square-container {
  min-width: 500px;
  width: 100%;
}
</style>

<style lang="scss">
.adjust-z-index {
  z-index: 9999 !important;
}
</style>