<template>
  <div v-if="middlewares">
    <run-category-direction 
      :run="run"
      :middlewares="middlewares"
      direction="$IN"
    />
    <div class="arrow-down pb-50 pl-1 text-secondary">
      <b-icon icon="arrow-down" scale="1.3" />
    </div>
    <run-category-direction 
      :run="run"
      :middlewares="middlewares"
      direction="$OUT"
    />
  </div>
  <div v-else>
    <div class="text-center p-2">
      <b-spinner variant="primary" type="grow" />
    </div>
  </div>
</template>

<script>
import Run from "./class/run";
import MiddlewareType from "@/custom/class/Middleware/Type.js";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

import { BAvatar, BCollapse, BIcon, BBadge, BCol, BRow, BListGroup, BListGroupItem, BButton, BSpinner, } from "bootstrap-vue";
import JsonViewer from "vue-json-viewer";

import RunCategoryCard from "./RunCategoryCard.vue";
import RunCategoryDirection from "./RunCategoryDirection.vue";

export default {
  components: {
    VuePerfectScrollbar,
    BAvatar,
    BCollapse,
    BIcon,
    BCol,
    BRow,
    BBadge,
    BListGroup,
    BButton,
    BListGroupItem,
    JsonViewer,
    RunCategoryCard,
    RunCategoryDirection,
    BSpinner,
  },
  props: {
    run: {
      type: Run,
      required: true,
    },
    middlewares: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      categoryShown: {},
      currentDisplaying: "category",
      middlewareVisible: {},
      middlewareOccurrenceVisible: {},
      showDataFromOccurrence: {},
    };
  },
  mounted() {

  },
  methods: {
    isCategoryVisible(identifier) {
      if (this.categoryShown[identifier] == undefined) {
        return this.hasElements(this.run.getCategory(identifier));
      }

      return this.categoryShown[identifier];
    },
    hasElements(obj) {
      return Object.keys(obj).length > 0;
    },
    getCategoryVariant(category_data) {
      if (!this.hasElements(category_data)) {
        return "light-secondary";
      }

      return "light-success";
    },
    toggleCategoryVisibility(identifier) {
      this.$set(
        this.categoryShown,
        identifier,
        !this.isCategoryVisible(identifier)
      );
    },
    getMiddleware(identifier) {
      return this.middlewares[identifier];
    },
    getMiddlewareType(type_id) {
      return new MiddlewareType(type_id);
    },
    toggleMiddlewareVisibility(identifier) {
      this.$set(
        this.middlewareVisible,
        identifier,
        this.middlewareVisible[identifier] == undefined
          ? true
          : !this.middlewareVisible[identifier]
      );
    },
  },
};
</script>

<style lang="scss">
.custom-bg {
  background: #0b1120 !important;
  border: solid 1px #ffffff61 !important;
}

.category-title {
  transform: all 0.1s ease-in;
  cursor: pointer !important;

  &:hover {
    opacity: 0.6;
  }
}

.striped-group-dark {
  background-color: #161d31 !important;

  // &:hover {
  //   background-color: #161d31 !important;
  // }
}

.group-item {
  // &:hover {
  //   background: local !important;
  //   opacity: 1;
  // }

  &:active {
    background: none !important; 
  }
}
</style>