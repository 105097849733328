<template>
  <div>
    <div class="run-card">
      <h4>{{$t("terminal_tests.run_log.run_info")}}</h4>
      <b-row>
        <b-col cols="4" v-if="run.webhook">
          <p class="small m-0 text-muted">{{$t("terminal_tests.run_log.webhook")}}</p>
          <p class="text-secondary font-weight-bolder">
            {{ run.webhook }}
          </p>
        </b-col>
        <b-col cols="4" v-if="run.sender">
          <p class="small m-0 text-muted">{{$t("terminal_tests.run_log.sender")}}</p>
          <p class="text-secondary font-weight-bolder">
            {{ run.sender }}
          </p>
        </b-col>
        <b-col cols="4" v-if="run.event">
          <p class="small m-0 text-muted">{{$t("terminal_tests.run_log.event")}}</p>
          <p class="text-secondary font-weight-bolder">
            {{ run.event }}
          </p>
        </b-col>
        <b-col cols="4" v-if="run.scheduler">
          <p class="small m-0 text-muted">{{$t("terminal_tests.run_log.scheduler")}}</p>
          <p class="text-secondary font-weight-bolder">
            {{ scheduler_name }}
          </p>
        </b-col>
        <b-col cols="12"> <hr /></b-col>
        <b-col cols="4">
          <p class="small m-0 text-muted">{{$t("terminal_tests.run_log.token")}}</p>
          <p class="text-secondary font-weight-bolder">
            {{ run.token }}
          </p>
        </b-col>
        <!-- level -->
        <b-col cols="4">
          <p class="small m-0 text-muted">{{$t("terminal_tests.run_log.origin")}}</p>
          <p class="text-secondary font-weight-bolder">
            {{ run.origin.type }}
          </p>
        </b-col>
        <b-col cols="4">
          <p class="small m-0 text-muted">{{$t("terminal_tests.run_log.log_level")}}</p>
          <p class="text-secondary font-weight-bolder">
            <b-badge class="text-uppercase" :variant="getLevelVariant(run.level)">
              {{ run.level }}
            </b-badge>
          </p>
        </b-col>
        <b-col cols="4">
          <p class="small m-0 text-muted">{{$t("terminal_tests.run_log.result")}}</p>
          <p class="text-secondary font-weight-bolder">
            <b-badge class="text-uppercase" :variant="run.success ? 'light-success' : 'light-danger'">
              <b-icon scale="1.75" :icon="run.success ? 'check' : 'x'" class="normalize-badge-icon" :class="run.success ? 'text-success' : 'text-danger'" />
              {{ $t(`terminal_tests.run_log.${run.success ? 'success' : 'failed'}`) }}
            </b-badge>
          </p>
        </b-col>
        <b-col cols="4">
          <p class="small m-0 text-muted">{{$t("terminal_tests.terms.total_time")}}</p>
          <p class="text-secondary font-weight-bolder">
            {{ (run.time / (1000 * 1000)).toLocaleString() }}s
          </p>
        </b-col>
        <b-col cols="4">
          <p class="small m-0 text-muted">{{$t("terminal_tests.run_log.sent_at")}}</p>
          <p class="text-secondary font-weight-bolder">{{ formatDate(run.created_at) }}</p>
        </b-col>
        <b-col cols="12">
          <p class="small m-0 text-muted">{{$t("terminal_tests.terms.message")}}</p>
          <div class="jv-dark rounded p-2">
            <span :class="run.success ? 'text-success' : 'text-danger'">{{ run.message }}</span>
          </div>
        </b-col>
        <b-col cols="12"> <hr /> </b-col>
        <b-col cols="12" class="mb-1" v-if="run.json_return.info.body">
          <p class="small m-0 text-muted">{{$t("terminal_tests.run_log.body_received")}}</p>
          <vue-perfect-scrollbar>
            <div style="max-height: 250px;">
              <json-viewer
                v-if="run.json_return.info.body"
                class="rounded mt-25 data-border"
                :value="run.json_return.info.body"
                :expand-depth="5"
                :theme="`jv-${$store.state.appConfig.layout.skin}`"
                copyable
                sort
              />
            </div>
          </vue-perfect-scrollbar>
        </b-col>
        <b-col cols="12" class="mb-1" v-if="run.json_return.info.response">
          <p class="small m-0 text-muted">{{$t("terminal_tests.run_log.final_response")}}</p>
          <vue-perfect-scrollbar>
            <div style="max-height: 250px;">
              <json-viewer
                class="rounded mt-25 data-border"
                :value="run.json_return.info.response.object"
                :expand-depth="5"
                boxed
                :theme="`jv-${$store.state.appConfig.layout.skin}`"
                copyable
                sort
              />
            </div>
          </vue-perfect-scrollbar>
        </b-col>
        <b-col cols="12">
          <p class="small m-0 text-muted">{{$t("terminal_tests.terms.response_code")}}</p>
          <div class="font-weight-bolder">
            <!-- {{ run.json_return.info }} -->
            <span v-if="run.json_return.info.response" :class="
              run.json_return.info.response.code >= 200 && run.json_return.info.response.code <= 300 
                ? 'text-success' : 'text-danger'">{{ run.json_return.info.response.code }}</span>
            <span v-else class="text-success">200</span>
          </div>
        </b-col>
      </b-row>
    </div>
    <div>
      <h3 class="text-muted text-center">Log execution history</h3>
      <run-category v-if="currentDisplaying == 'category'" :run="run" :middlewares="middlewares" />
    </div>
  </div>
</template>

<script>
import Run from "./class/run";
import {
  BCard,
  BCardHeader,
  BAvatar,
  BCollapse,
  BIcon,
  BCol,
  BRow,
  BListGroup,
  BListGroupItem,
  BBadge,
  BContainer,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import JsonViewer from "vue-json-viewer";
import HelperTooltip from "@/layouts/components/HelperTooltip.vue";
import RunCategory from "./RunCategory.vue";
import Timeline from "../Timeline.vue";
import moment from "moment";

export default {
  components: {
    BCard,
    BCardHeader,
    VuePerfectScrollbar,
    BAvatar,
    BCollapse,
    BIcon,
    BCol,
    BRow,
    JsonViewer,
    HelperTooltip,
    BListGroup,
    BListGroupItem,
    RunCategory,
    Timeline,
    BBadge,
    BContainer,
  },
  data() {
    return {
      categoryShown: {},
      currentDisplaying: "category",
      scheduler_name: "",
      middlewares: undefined,
    };
  },
  props: {
    run: {
      type: Run,
    },
  },
  computed: {
    transmissionID() {
      return this.$route.params.transmissionID;
    },
  },
  mounted() {
    if(this.run.scheduler) {
      this.getSchedulerName(this.run.scheduler);
    }

    this.getRunMiddlewares();
  },
  methods: {
    mountFromScript(script) {
      let keys = [];
      if(script.middlewares) {
        Object.keys(script.middlewares).forEach((key) => {
          keys.push(...script.middlewares[key]);
        });
      }

      while(keys.length) {
        if(this.middlewares === undefined) {
          this.middlewares = {};
        }

        let middleware = keys.pop();

        if(middleware.middlewares) {
          keys.push(...middleware.middlewares);
        }

        this.$set(this.middlewares, middleware.identifier, middleware);
      }
    },
    getRunMiddlewares() {
      if(this.run.script_id && parseInt(this.run.script_id) > 0) {
        this.$store.dispatch('getCompiledScriptByID', {
          transmission_id: this.transmissionID,
          id: this.run.script_id,
        }).then((resp) => {
          const script = JSON.parse(resp.script);
          this.mountFromScript(script);
        }).catch((err) => {
          console.error(err);          
        })
      } else {
        this.$store.dispatch('simulateCompiledScript', {
          transmission_id: this.transmissionID,
          id: this.run.script_id,
        }).then((resp) => {          
          this.mountFromScript(resp);
        }).catch((err) => {
          console.error(err);          
        })
      }
    },
    setDisplay(display) {
      this.currentDisplaying = display;
    },
    formatDate(value) {
      if (!value) return null
      let date = new Date(value)
      return moment(date).format(this.$t('scheduler.view.moment_date_format'));
    },
    getLevelVariant(level) {
      const map = {
        info: 'light-info',
        warning: 'light-warning',
        error: 'light-danger'
      };

      return map[level] || 'light-secondary'
    },
    getSchedulerName(identifier) {
      this.scheduler_name = identifier;
      this.$store.dispatch('getSchedulerByIdentifier', {
        transmission_id: this.transmissionID,
        identifier,
      }).then((resp) => {
        this.scheduler_name = resp.name;
      }).catch((err) => {})
    }
  }
};
</script>

<style lang="scss" scoped>
.run-card {
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  background: #0b1120 !important;
  border: solid 1px #ffffff61 !important;
}

.run-btn {
  .active {
    background: #ff9f43 !important;
  }
}
</style>