<template>
  <div>
    <b-overlay :show="userCantEdit" bg-color="none" blur="0" :class="userCantEdit ? 'less-opacity-overlay' : ''">
      <template #overlay><span/></template>
      <div class="btn round edit-button py-25 px-50" :class="item ? 'btn-outline-warning' : 'btn-outline-success'" @click="openChanger">
        <feather-icon
          :icon="item ? 'Edit2Icon' : 'PlusIcon'"
          :size="item ? '14' : '17'"
        />
      </div>

      <b-modal
        :id="getID(field)"
        :title="$t(`default_changer.${i18nKey}.title`)"
        centered
        lazy
        @ok="changeValue"
      >
        <b-form-group
          v-if="isSelect && !isBusy"
          :label="$t(`default_changer.${i18nKey}.input.label`)"
          label-for="input"
        >
          <b-form-select
            v-model="new_value"
            label-field="name"
          >
            <template #default>
              <slot name="options">
                <b-form-select-option
                  v-for="(option, index) in options"
                  :key="`${field}-${index}`"
                  :value="{ [optionValue]: option[optionValue], [optionLabel]: option[optionLabel] }"
                >
                  {{ $t(option[optionLabel]) }}
                </b-form-select-option>
              </slot>
            </template>
            
            <template #first>
              <b-form-select-option 
                :value="null" 
                disabled
              >
                {{ $t(`common.terms.choose`) }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
        
        <b-form-group
          v-else-if="isInput"
          :label="$t(`default_changer.${i18nKey}.input.label`)"
          label-for="input"
        >
          <cleave
            v-if="config"
            id="input"
            v-model="new_value"
            class="form-control"
            :raw="false"
            :options="config"
          />
          <b-form-input
            v-else
            id="input"
            :placeholder="$t(`default_changer.${i18nKey}.input.placeholder`)"
            v-model="new_value"
            @keydown.enter="changeValue"
          />
        </b-form-group>
        <b-form-textarea 
          v-else-if="isTextarea"
          :placeholder="$t(`default_changer.${i18nKey}.input.placeholder`)"
          v-model="new_value"
        />
        <b-form-group 
          v-else-if="isDatePickr"
          :label="$t(`default_changer.${i18nKey}.input.label`)"
          label-for="time-pickr"
        >
          <flat-pickr
            id="time-pickr"
            v-model="new_value"
            class="form-control"
            autocomplete="off"
            autofocus
            :config="config"
          />
        </b-form-group>
        <b-skeleton 
          v-else
          type="input"
        />
      </b-modal>
    </b-overlay>
  </div>
</template>

<script>

import { BButton, BModal, BFormSelect, BFormInput, BSkeleton, BFormSelectOption, BFormTextarea, BFormGroup, BOverlay } from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import { v4 as uuidv4 } from "uuid";
import VSelect from 'vue-select'
import { makeToast } from '@/layouts/components/Popups.js'
import FlatPickr from 'vue-flatpickr-component';
import "flatpickr/dist/themes/dark.css";

  export default {
    components: {
      BButton,
      BModal,
      BFormSelect,
      BFormInput,
      BFormSelectOption,
      BSkeleton,
      BFormTextarea,
      VSelect,
      Cleave,
      BFormGroup,
      FlatPickr,
      BOverlay
    },
    props: {
      //* Field that will be patched to call the api
      field: {
        type: String,
        default: null
      },
      //* Type of the field to edit example: 'vselect', 'input', 'textarea'
      type: {
        type: String,
        required: true,
      },
      //* Submit action that calls a module to submit the change
      action: {
        type: String,
        required: true,
      },
      //* If options needed this receives the action and filters to get them. 
      //* example: "{ action: 'getOrganizationByLoggedUser', filter:{ order: 'id DESC' }}"
      fetchFunction: { 
        type: Object,
        default: null
      },
      settedOptions: {
        type: undefined,
        default: null
      },
      value: {
        type: undefined,
        default: undefined
      },
      //* when select sets the options label
      optionLabel: {
        type: String,
        default: 'label',
      },
      //* when select sets the options value
      optionValue: {
        type: String,
        default: 'id',
      },
      //* aditional data sent to the action in a module. example: 'transmissionID'
      aditionalData: {
        type: Object,
        default: null, 
      },
      busy: {
        type: Boolean,
        default: false
      },
      //* Custom label and toasts
      i18nKey: {
        type: String,
        default: "default"
      },
      config: {
        type: Object,
        default: () => {}
      },
      formatter: {
        type: Function,
        default: (value) => { return value }
      },
      unformatter: {
        type: Function,
        default: (value) => { return value }
      },
    },
    data() {
      return {  
        uuidMap: {},
        new_value: null,
        options: null,
        fetching: false,
      }
    },
    mounted() {
      this.new_value = this.formatter(this.item)
      if(this.isSelect) {
        this.new_value = {
          [this.optionLabel]: this.item[this.optionLabel],
          [this.optionValue]: this.item[this.optionValue],
        }
      }
    },
    computed: {
      item: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      
      isBusy() {
        return this.busy || !this.options || this.fetching;
      },
      //* --- changer types --- *//
      isSelect() {
        return this.type == 'select'
      },
      isVueSelect() {
        return this.type == 'vselect'
      },
      isInput() {
        return this.type == 'input'
      },
      isTextarea() {
        return this.type == 'textarea'
      },
      isDatePickr() {
        return this.type == 'datepickr'
      },
      //* -------------------- *//
      isArray() {
        return this.item instanceof Array;
      },
      isObject() {
        return this.item instanceof Object && !this.isArray;
      },
      isIterable() {
        return this.isArray || this.isObject;
      },
      userCantEdit(){
        return !this.$can('create')
      },
    },
    methods: {
      openChanger() {
        if(this.type !== 'input') {
          if(this.fetchFunction) {
            this.getOptions(this.fetchFunction.filter || null)
          } else if (this.settedOptions){
            this.options = this.settedOptions
          } else {
            this.$emit('loadOptions')
          }
        }
        this.$bvModal.show(this.getID(this.field))
      },
      changeValue() {
        this.$bvModal.hide(this.getID(this.field))
        let new_value = this.unformatter(this.new_value);
        if(this.isSelect) {
          new_value = this.new_value[this.optionValue];
        }
        
        this.$store.dispatch(this.action, { new_value, field: this.field, ...this.aditionalData })
          .then(() => {
            if (this.isIterable) {
              this.item = {
                ...this.item,
                ...this.new_value
              }
            } else {
              this.item = this.unformatter(this.new_value)
            }
            makeToast({
              title: this.$t(`default_changer.${this.i18nKey}.toast.submit.success.title`),
              text: this.$t(`default_changer.${this.i18nKey}.toast.submit.success.text`),
              variant: "success",
              icon: "CheckIcon",
            })
        }).catch((error) => {
          console.log(error)
          makeToast({
            title: this.$t(`default_changer.${this.i18nKey}.toast.submit.error.title`),
            text: this.$t(`default_changer.${this.i18nKey}.toast.submit.error.text`),
            variant: "danger",
            icon: "XIcon",
          })
        })
      },
      getOptions() {
        if (!this.options) {
          this.fetching = true;
          this.$store.dispatch(this.fetchFunction.action, this.fetchFunction.filter)
            .then((response) => {
              this.options = response
              this.fetching = false;
          }).catch(() => {
            makeToast({
              title: this.$t(`default_changer.${this.i18nKey}.toast.get_options.error.title`),
              text: this.$t(`default_changer.${this.i18nKey}.toast.get_options.error.text`),
              variant: "danger",
              icon: "XIcon",
            })
            this.fetching = false;
          })
        }
      },
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
    },
  }
</script>

<style lang="scss" scoped>

.less-opacity-overlay{
  cursor: not-allowed;
  filter: opacity(0.5);
}

.edit-button {
  opacity: 1;
  &:hover {
    opacity: 0.7
  }
}
</style>