import Middlewares from "./../Enum/Middlewares";

export default class Type {
  GENERATE_COMPONENT_UUID = '{{GENERATE_COMPONENT_UUID}}';
  GENERATE_NEW_UUID = '{{GENERATE_NEW_UUID}}';

  constructor(id) {
    const mid = new Middlewares();
    // const item = mid.items[parseInt(id) - 1];
    const item = mid.items.find(element => element.id == id )

    Object.keys(item).forEach((key) => {
      this[key] = item[key];
    });
  }

  getProps(vue) {
    let props = {};
		
    if(!this.props) {
      return props;
    }

    this.props.forEach((el) => {
      let value = vue[el.value] || null;
      if(el.value == this.GENERATE_COMPONENT_UUID) {
        value = vue.getID(this.component);
      }

      if(el.value == this.GENERATE_NEW_UUID) {
        value = vue.getID(el.uuidkey);
      }

      props[el.prop] = value;
    });

    return props;
  }
}
