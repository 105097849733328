<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >
    <!-- Content Left -->
    <div class="btn-flat-none" size="sm">
      <feather-icon
        v-b-tooltip.hover.v-black
        :title="$t('navigation.tooltip.back')"
        icon="ChevronLeftIcon"
        class="text-primary cursor-pointer"
        size="30"
        @click="shiftBackOnce()"
      />
    </div>
    <b-col class="content-header-left mb-2">
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            <helper iconSize="23" innerHTML="ooooooooooooooppppppppppppaaaaaaaaaaaaaaaaa"/>
            {{ $route.meta.pageTitle }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/home">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
  VBToggle,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Helper from "@/layouts/components/Helper.vue";

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    Helper
  },
  directives: {
    ripple: Ripple,
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  methods: {
    showModal() {
      this.$store
        .dispatch("setModalObj", `modal${this.$route.meta.pageTitle}Add`)
        .then(() => {
          this.$store.dispatch("setActiveModal", true)
        })
        .catch(() => {
          console.log(error);
        });
    },
    shiftBackOnce(){
      this.$router.go(-1);
    }
  },
};
</script>
