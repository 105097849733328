<template>
  <div>
    <div class="run-card pb-50 mb-50">
      <h4>{{ name }}</h4>
    </div>
    <div v-if="!run.json_return.logs[direction] || !run.json_return.logs[direction].length">
      <div class="arrow-down pb-50 pl-1 text-secondary">
        <b-icon icon="arrow-down" scale="1.3" />
      </div>
      <div class="run-card pb-1 mb-1">
        <h5 class="font-weight-bolder text-muted p-0 m-0">No middlewares executed</h5>
      </div>
    </div>
    <div v-else>
      <div v-for="(log, idx) in middlewaresSorted" :key="getID(`middleware-${idx}`)">
        <run-category-card 
          :middleware="middlewares[log.middleware_identifier]"
          :middlewares="middlewares"
          :log="log"
          :run="run"

          :previous="(idx - 1 >= 0) ? middlewaresSorted[idx - 1] : undefined"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Run from "./class/run";
import RunCategoryCard from "./RunCategoryCard.vue";
import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    RunCategoryCard,
  },
  props: {
    run: {
      type: Run,
      required: true,
    },
    direction: {
      type: String,
      required: true,
    },
    middlewares: {
      required: true,
    },
  },
  data() {
    return {
      uuidMap: {},
      middlewaresSorted: undefined,
    }
  },
  mounted() {
    this.middlewaresSorted = this.run.sortMiddlewares(this.run.json_return.logs[this.direction] || undefined);
  },
  computed: {
    name() {
      return {
        '$IN': 'IN',
        '$OUT': 'OUT',
      }[this.direction];
    }
  },
  methods: {
    getID(key) {
      if (this.uuidMap[key]) {
        return this.uuidMap[key];
      }

      const uuid = uuidv4();
      this.uuidMap[key] = uuid;

      return uuid;
    },
  }
}
</script>

<style>
.run-card {
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  background: #0b1120 !important;
  border: solid 1px #ffffff61 !important;
}
</style>