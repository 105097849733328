<template>
  <div v-if="data && Array.isArray(data)" class="middleware-log-container">
    <div class="mb-1" v-for="(log, idx) in data"  :key="`middleware-log-${uuid}-${idx}`">
      <div>
        <b-icon v-if="agentBlock" icon="circle-fill" scale="0.4" class="mr-25" :variant="blockDir[agentBlock]" />
        
        <template v-if="agentBlock && log.type && log.type != 0">
          <span  class="agent-type-title">
            {{ $t(getAgentType(log.type).label) }}
          </span> 
          
          <b-icon icon="arrow-right-short" class="mx-50" shift-v="-1" scale="1.3" :variant="blockDir[agentBlock]"/>
        </template>

        <span :class="{'border-bottom-warning-low-opacity' : log.error}" class="">

        <span class="mr-25 small text-warning font-weight-bolder" v-if="log.error">
          <b-icon icon="exclamation-triangle" variant="warning" scale="0.75" class="m-0" shift-v="-1"/>  
          ERROR
        </span>

        <span class="font-weight-bolder ml-1 mb-0">{{ log.message }}</span></span>

        <b-button
          class="py-25 px-50 ml-1" variant="outline-light" v-if="!isMiddleware && log.data && (typeof log.data != 'object' || Object.keys(log.data).length > 0)"
          @click="visibility['agent-'+idx] = !visibility['agent-'+idx]"
        >
          <span class="small font-weight-bold" v-if="visibility['agent-'+idx]">
            Hide
          </span>
          <span class="small font-weight-bold" v-else>
            Show
          </span>
        </b-button>
      </div>
      <b-collapse v-if="visibility && log.data" :visible=" isMiddleware || visibility['agent-'+idx]" class="mx-1">

        <template v-if="getAgentType(log.type)">
          <template v-if="getAgentType(log.type).id == 18"> <!-- Javascript Agent -->
            <div v-for="logType in  Object.keys(log.data)" :key="`log-type-${logType}-${uuid}`">
              <div>
                <div class="font-weight-bolder">Console {{logType}}s:</div>
                
                <div v-for="(consoleLog, consoleIdx) in log.data[logType]" class="mb-25" :key="`consolelog-${consoleIdx}-${logType}-${uuid}`">
                  <custom-pre :data="consoleLog" minHeight="unset"/>
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="29"> <!-- Debug Payload Agent -->
            <custom-pre :data="JSON.parse(log.data)"/>
          </template>
        </template>
        <custom-pre v-else class="" :data="log.data"/>
      
      </b-collapse>
    </div>

    
 </div>
</template>

<script>
import {
  BCard,
  BCollapse,
  BIcon,
  BBadge,
  BButton,
  BTooltip,
  VBTooltip,
} from "bootstrap-vue";
import moment from "moment";
import Middlewares from "@/custom/class/Enum/Middlewares.js"
import Agents from "@/custom/class/Enum/Agents.js"
import CustomPre from '@/views/pages/middleware/MiddlewareManagement/Components/CustomPre.vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {  
    BCard,
    BCollapse,
    BIcon,
    BBadge,
    BButton,
    BTooltip,
    VBTooltip,
    CustomPre,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      uuid: uuidv4(),
      blockDir:{
        try: "info",
        then: "success",
        catch: "danger",
      },
      isMiddleware: false,
      visibility:{},
    }
  },
  props: {
    data: {
      type: [Object, Array],
      default: undefined 
    },
    agentBlock: {
      type: String,
      default: undefined 
    },
  },
  mounted () {
    if (this.data[0] && this.data[0].type == 0){
      this.isMiddleware = true
    }

    let visible = {}
    let idx = 0
    this.data.forEach(l => {
      visible['agent-'+idx] = false

      idx = idx + 1;
    });
    this.visibility = visible
  },
  methods: {
    formataDate(date) {
      date = new Date(date)
      return new moment(date).calendar()
    },
    middlewareType(enumId){
      return new Middlewares().items.find(el => el.id == enumId)
    },
    getAgentType(id){
      if (id != 0){
        return new Agents().items.find(el => el.id == id)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/test-terminal.scss';

.border-bottom-warning-low-opacity{
  border-bottom: 1px solid transparentize($warning, 0.6) !important;
}

.agent-type-title{
  background-color: transparentize($light, 0.3);
  color: $black;
  padding: 1px 3px;
  font-weight: 600;
  border-radius: 3px;
}

</style>