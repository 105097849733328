export default [
  {
    key: "testTerminal",
    name: "Run Test",
    titleIcon: 'play',
    component: "TestTerminal",
    closable: false,
    properties: {}
  },
  // {
  //   key: "webhookTerminal",
  //   name: "terminal_tests.tabs.webhook_caller",
  //   component: "WebhookTerminal",
  //   closable: false,
  //   properties: {}
  // },
];