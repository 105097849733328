<template>
  <div class="d-inline-block helper-container">
    <b-button 
      :id="uuid"
      type="button" 
      pill
      size="sm"
      class="px-0 py-0 square"
      variant="flat-warning"
    >
      <!-- variant="outline-info" -->
      <feather-icon
        :size="size"
        icon="HelpCircleIcon"
      />
    </b-button>
    <b-tooltip :placement="tooltipPlacement" :target="uuid" custom-class="adjust-z-index">
      <span v-if="innerHTML" v-html='innerHTML'></span>
      <span v-else>{{ innerText }}</span>
    </b-tooltip>
  </div>
</template>

<script>
import { BButton, BTooltip } from 'bootstrap-vue'
import { v4 as uuidv4 } from "uuid";

  export default {
    props: {
      innerText: {
        type: String,
        default: "",
      },
      innerHTML: {
        type: String,
        default: undefined,
      },
      tooltipPlacement: {
        type: String,
        default: "auto"
      },
      size: {
        type: [ Number, String],
        default: '20'
      }
      
    },
    components: {
      BButton,
      BTooltip,
    },
    data() {
      return { 
        uuid: uuidv4(),
      }
    },
  }
</script>

<style lang="scss" scoped>

// @import "@/assets/scss/variables/_variables.scss";

.helper-container {
  position: relative;
  top: -3px;
}

</style>

<style lang="scss">
.adjust-z-index {
  z-index: 9999 !important;
}
</style>