<template>
  <div>
    <v-select
      :appendToBody="true"
      :options="options"
      :getOptionLabel="(option) => option.name"
      :reduce="(option) => option.id"
      v-model="selectedOrganization"
      @input="setSelectedOrganization()"
      :clearable="false"
    />
  </div>
</template>

<script>
import vSelect from "vue-select";
import EventBus from "@/custom/eventBus";
import { makeToast } from '@/layouts/components/Popups.js'

export default {
  components: {
    vSelect,
  },

  data() {
    return {
      options: [],
      selectedOrganization: null,
    };
  },

  mounted() {
    EventBus.$on("reloadOrg", () => {
      this.init();
    })

    this.init();
  },

  beforeDestroy() {
    EventBus.$off("reloadOrg")
  },

  methods: {
    init() {
      this.fetchOrganization();
    },
    fetchOrganization() {
      this.$store
        .dispatch("getOrganizationByLoggedUser")
        .then((response) => {          
          let idx = response.findIndex(el=> String(el.id) == localStorage.getItem('selectedOrganization') )

          if (localStorage.getItem('selectedOrganization') && idx > -1) {

            this.selectedOrganization = parseInt(
              localStorage.getItem('selectedOrganization')
            );
            this.$store.dispatch("setSelectedOrganization", this.selectedOrganization);

          } else {
            this.selectedOrganization = response[0].id;
            localStorage.setItem('selectedOrganization', this.selectedOrganization)
            this.$store.dispatch('setSelectedOrganization', this.selectedOrganization);
          }

          this.options = response;
        })
        .catch(() => {
          makeToast({
            title: '',
            text:'',
            variant: "danger",
            icon: "XIcon",
          }); 
        });
    },
    setSelectedOrganization() {

      localStorage.setItem('selectedOrganization', this.selectedOrganization)
      this.$store.dispatch('setSelectedOrganization', this.selectedOrganization);
      EventBus.$emit('loadTransmissionGroupSelection');
      if(this.$route.name != "home"){
        this.$router.push({
          name: 'home',
        })
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
.v-select {
  width: 300px;
}
</style>