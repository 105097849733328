<template>
  <div class="w-100 pl-3 pr-2 card">
    <div v-if="service">
      <div class="card-header">
        <b-row class="w-100 justify-content-between">
          <b-col md="6">
            <div class="d-inline-block pr-2">
              <b-avatar
                v-if="service"
                size="105px"
                :src="`https://robohash.org/${service.id}`"
              />
            </div>
            <div class="d-inline-block">
              <span>{{ $t("common.terms.service") }}</span>
              <div class="mt-1">
                <h2 class="d-inline-block"><feather-icon v-b-tooltip.hover.top="$t(lockIcon.text)" :icon="lockIcon.icon" :class="lockIcon.variant" size="18" /> {{ service.name }}</h2>
                <default-changer 
                  class="d-inline-block"

                  field="name"
                  action="editWebService"
                  type="input"
                  v-model="service.name"
                  :aditionalData="{ serviceID: serviceID }"
                />
              </div>
            </div>
          </b-col>
          <b-col md="6">
            <div class="d-inline-block w-50">
              <p class="small text-muted mb-0">{{ $t('integrator.terms.owned_by') }}</p>
              <h5 class="font-weight-bold d-inline-block">{{ service.organization.name }}</h5>
              
              <default-changer
                class="d-inline-block"

                field="organization"
                action="editWebService"
                type="select"
                v-model="service.organization"
                :aditionalData="{ serviceID: serviceID }"
                :busy="busy"
                :fetchFunction="{ action: 'getOrganizationByLoggedUser', filter:{ order: 'id DESC' }}"

                optionLabel="name"
                optionValue="id"
              >
              </default-changer>
            </div>
            <div class="d-inline-block w-50">
              <div>
                <p class="small text-muted mb-0">{{ $t('integrator.terms.host') }}</p>
                <h5 class="font-weight-bold d-inline-block">{{ service.host }}</h5>
                <default-changer
                  class="d-inline-block"

                  field="host"
                  action="editWebService"
                  type="input"
                  v-model="service.host"
                  :aditionalData="{ serviceID: serviceID }"
                />
              </div>
              <div>
                <p class="small text-muted mb-0">{{ $t('integrator.terms.created_at') }}</p>
                <h5 class="font-weight-bold d-inline-block">{{ createdAt }}</h5>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="card-body">
        <b-row class="w-100 justify-content-center" v-if="service">
          <div v-for="(button, key) in buttons" :key="`btn-tg-nav-${key}`">
            <b-dropdown
              v-if="button.items"
              split
              class="mr-2"
              :id="`dropdown-tg-nav-${key}`"
              :text="button.text"
              :variant="button.variant"
            >
              <b-dropdown-item v-for="(data, dp_key) in button.items" :to="data.action" :key="`btn-tg-nav-${dp_key}`">{{ data.text }}</b-dropdown-item>
            </b-dropdown>
            <b-button
              v-else
              class="mr-2"
              :variant="button.variant"
              :to="button.action"
            >
              <feather-icon
                v-if="button.icon"
                :icon="button.icon"
              />
              {{ button.text }}
            </b-button>
          </div>
        </b-row>
      </div>
    </div>
    <div v-else>
      <div class="card-header">
        <b-skeleton height="30px" width="100%"></b-skeleton>
        <b-skeleton height="30px" width="100%"></b-skeleton>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BLink,
  BFormSelect,
  BFormSelectOption,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BSkeleton,
  BDropdown,
  VBTooltip,
} from "bootstrap-vue";

import vSelect from "vue-select";
import Helper from "@/layouts/components/Helper.vue";
import { makeToast } from "@/layouts/components/Popups.js";
import moment from 'moment';
import DefaultChanger from '@/layouts/components/DefaultChanger.vue';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdown,
    BDropdownDivider,
    BAvatar,
    BButton,
    BSkeleton,
    VBTooltip,
    vSelect,
    Helper,
    BFormSelect,
    BFormSelectOption,
    DefaultChanger,
  },
  data() {
    return {
      options: [],
      currentEnv: undefined,
      buttons: [],
      transmissions: null,
      organizations: null,
      new_value: null,
      busy: false,
    };
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  computed: {
    service() {
      return this.$store.getters.getSelectedWebService
    },
    serviceID() {
      return this.$route.params.serviceID;
    },
    lockIcon() {
      return (this.service.private) ? { icon: 'LockIcon', variant: 'text-danger', text: 'integrator.tooltip.private' } : { icon: 'UnlockIcon', variant: 'text-success', text: 'integrator.tooltip.public' };
    },
    fullURI() {
      return `${this.service.scheme}://${this.service.host}`;
    },
    createdAt() {
      return moment(this.service.created_at).format("LLL");
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.fetchService();
    },
    fetchService() {
      this.$store
        .dispatch("getWebServiceByID", { id: this.serviceID })
        .then(() => {
          this.buildButtons();
        })
        .catch(() => {
          makeToast({
            title: this.$t("integrator.toast.get_by_id.error.title"),
            text: this.$t("integrator.toast.get_by_id.error.message"),
            variant: "danger",
            icon: "XIcon",
          }); 
        });
    },
    buildButtons() {
      this.buttons = [
        {
          text: this.$t("integrator.nav.home"),
          variant: "outline-secondary",
          icon: "HomeIcon",
          action: {
            name: "integrator-service-view",
            params: {
              serviceID: this.service.id,
            },
          },
        },
        {
          text: this.$t("integrator.nav.request"),
          variant: "outline-warning",
          icon: "ListIcon",
          action: {
            name: "integrator-service-request",
            params: {
              serviceID: this.service.id,
            },
          },
        },
        {
          text: this.$t("integrator.nav.webhook"),
          variant: "outline-success",
          icon: "AnchorIcon",
          action: {
            name: "integrator-service-webhook",
            params: {
              serviceID: this.service.id,
            },
          },
        },
        {
          text: this.$t("integrator.nav.releases"),
          variant: "outline-info",
          icon: "GitBranchIcon",
          action: {
            name: "integrator-service-release",
            params: {
              serviceID: this.service.id,
            },
          },
        },
       
      ];
    },
    // fetchTransmissionGroupByOrganization() {
    //   if(!this.transmissions){
    //     this.busy = true
    //     this.$store
    //     .dispatch("getTransmissionByOrganization", { order: "id DESC" })
    //     .then((response) => {
    //       this.transmissions = response
    //       this.busy = false
    //     }).catch((error) => {
    //       makeToast({
    //         title: this.$t("integrator.toast.get_transmissions.error.title"),
    //         text: this.$t("integrator.toast.get_transmission.error.message"),
    //         variant: "danger",
    //         icon: "XIcon",
    //       })
    //       this.busy = false
    //     })
    //   }
    // },
    // fetchOrganizationByUser() {
    //   if(!this.organizations){
    //     this.busy = true
    //     this.$store
    //     .dispatch("getOrganizationByLoggedUser", { order: "id DESC" })
    //     .then((response) => {
    //       this.organizations = response
    //       this.busy = false
    //     }).catch((error) => {
    //       makeToast({
    //         title: this.$t("integrator.toast.get_transmissions.error.title"),
    //         text: this.$t("integrator.toast.get_transmission.error.message"),
    //         variant: "danger",
    //         icon: "XIcon",
    //       })
    //       this.busy = false
    //     })
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
</style>